import { formatAmount } from '~/components/amount'
import { Grid } from '~/components/layout'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { DonationRecurrenceInterval } from '~/types/graphql'

import { PreviewTiers } from './components/preview-tiers'
import { Tier } from './components/tier'
import { EditorText } from '~/pages/dashboard/components/editor'

type Props = {
  closed: boolean
  slug: string
  donationTiers: PageBySlugQuery['pageBySlug']['donationTiers']
  fundraiserId?: string
  allowCustomAmount: boolean
}

// TODO: lock to only 4 tiers per FP

const labels = [
  {
    label: 'One-Time',
    value: DonationRecurrenceInterval.None,
  },
  {
    label: 'Monthly',
    value: DonationRecurrenceInterval.Monthly,
  },
  {
    label: 'Quarterly',
    value: DonationRecurrenceInterval.Quarterly,
  },
  {
    label: 'Yearly',
    value: DonationRecurrenceInterval.Yearly,
  },
]

const isText = (value: any): value is EditorText => {
  return typeof value.text === 'string'
}

// TODO: make this generic and use the tab-story serializer as well
const serialize = (node: any, index: number, _: any[]): any => {
  if (isText(node)) {
    let text: React.ReactNode = node.text

    if (node.bold) {
      text = <strong key={'bold' + index}>{text}</strong>
    }

    if (node.italic) {
      text = <em key={'italic' + index}>{text}</em>
    }

    return text
  }

  // TODO: Fix types
  const children = (node.children as any).map(serialize)

  switch (node.type) {

    case 'paragraph': {
      return <p key={node.type + index}>{children}</p>
    }

    case 'list': {
      return (
        <ul
          key={node.type + index}
          className="pl-4 list-disc"
        >
          {children}
        </ul>
      )
    }

    case 'list-item': {
      return (
        <li key={node.type + index}>
          {children}
        </li>
      )
    }

  }
}

export const parseDescription = (description: string) => {
  if (!description) {
    return ""
  }

  try {
    const story = JSON.parse(description)

    return <>{story.map(serialize)}</>
  } catch (e) {
    return description
  }
}

export const DonationTiers = ({
  closed,
  slug,
  donationTiers,
  fundraiserId,
  allowCustomAmount,
}: Props) => {
  return (
    <Grid gridRowGap={20}>
      {donationTiers.length === 0 && <PreviewTiers />}
      {donationTiers.map(tier => (
        <Tier
          key={tier.id}
          slug={slug}
          fundraiserId={fundraiserId}
          tierId={tier.id}
          heading={`${formatAmount(tier.amount)}${' ' + labels.find(i => i.value == tier.recurrenceInterval)?.label
            }`}
          description={parseDescription(tier.description)}
          disabled={closed}
        />
      ))}
      {allowCustomAmount && (
        <Tier
          slug={slug}
          fundraiserId={fundraiserId}
          tierId="custom"
          heading="Donate Custom Amount"
          description="Choose your custom amount. Every dollar makes a difference."
          disabled={closed}
        />
      )}
    </Grid>
  )
}
