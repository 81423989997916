import { Wrapper, TierHeading } from './style'
import { Meta } from '~/components/typography'
import { Link } from '~/components/link'
import { routes } from '~/config/routes'
import { useUtmQuery } from '~/hooks/use-utm-query'
import { colors } from '~/styles/colors'

type Props = {
  tierId: string
  heading: string
  description: React.ReactNode
  disabled: boolean
  slug: string
  fundraiserId?: string
}

export const Tier = ({
  heading,
  description,
  disabled,
  slug,
  fundraiserId,
  tierId,
}: Props) => {
  const utmQuery = useUtmQuery()
  const url = routes.fundraisingPage.checkout(slug, fundraiserId)

  if (disabled) {
    return (
      <Wrapper
        style={{
          background: colors.soft,
          cursor: 'not-allowed',
        }}
      >
        <TierHeading style={{ color: colors.slateGray }}>{heading}</TierHeading>
        <Meta regular color={colors.slateGray}>
          {description}
        </Meta>
      </Wrapper>
    )
  }

  return (
    <Link href={url} query={{ tier: tierId, ...utmQuery }}>
      <Wrapper>
        <TierHeading>{heading}</TierHeading>
        <Meta regular>{description}</Meta>
      </Wrapper>
    </Link>
  )
}
